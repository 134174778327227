import { request, getUserAuthToken, uploadToS3 } from '../utils';

const getters = {
  getWellgroups: (state) => {
    const wellgroupsSorted = state.wellgroups.slice();
    wellgroupsSorted.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt));
    return wellgroupsSorted;
  },
  getWellgroup: (state) => (wellgroupId) => state.wellgroups.find((w) => w.id === wellgroupId),
  getWellgroupFolders: (state) => {
    const sortedFolders = state.folders.slice().sort((a, b) => a.name.localeCompare(b.name));

    return sortedFolders;
  },
  getLogs: (state) => state.status.logs,
};

const actions = {
  async fetchWellgroups({ commit }, { clientId }) {
    commit('SET_LOADING', { operation: 'read', loading: true });
    commit('SET_ERROR', null);
    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/clients/${clientId}/wellgroups`;
      const token = await getUserAuthToken();
      const result = await request(url, 'GET', token);
      const wellgroups = JSON.parse(result);

      wellgroups.forEach((wellgroup, index) => {
        let numWellsWithCoords = 0;
        wellgroup.wells.forEach((well) => {
          if (well.topSurfaceLatitude && well.topSurfaceLongitude) {
            numWellsWithCoords += 1;
          }
        });

        wellgroups[index].numWellsWithCoords = numWellsWithCoords;
      });

      commit('SET_WELLGROUPS', wellgroups);
    } catch (err) {
      commit('SET_ERROR', err);
      console.error(`Unable to retrieve list of well groups: ${err}`);
    }
    commit('SET_LOADING', { operation: 'read', loading: false });
  },
  async viewWellgroup({ commit }, { wellgroup }) {
    commit('SET_LOADING', { operation: 'read', loading: true });
    commit('SET_ERROR', null);
    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/wellgroups/${wellgroup.id}/url`;
      const token = await getUserAuthToken();
      const result = await request(url, 'GET', token);
      const json = JSON.parse(result);
      const arg1 = encodeURIComponent(json.signedUrl);
      const arg2 = encodeURIComponent(wellgroup.displayName);
      console.log(`${process.env.VUE_APP_UNITY_APP_URL}/index.html?arg1=${arg1}&arg2=${arg2}`);
      window.open(`${process.env.VUE_APP_UNITY_APP_URL}/index.html?arg1=${arg1}&arg2=${arg2}`, '_blank');
    } catch (err) {
      console.error(err);
      commit('SET_ERROR', err);
    }
    commit('SET_LOADING', { operation: 'read', loading: false });
  },
  async deleteWellgroup({ commit }, { wellgroupId }) {
    commit('SET_LOADING', { operation: 'delete', loading: true });
    commit('SET_ERROR', null);
    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/wellgroups/${wellgroupId}`;
      const token = await getUserAuthToken();
      await request(url, 'DELETE', token);
      commit('DELETE_WELLGROUP', wellgroupId);
    } catch (err) {
      commit('SET_ERROR', err);
    }
    commit('SET_LOADING', { operation: 'delete', loading: false });
  },
  async createWellgroup({ commit }, {
    clientId, displayName, file, folderId,
  }) {
    commit('SET_LOADING', { operation: 'create', loading: true });
    commit('SET_ERROR', null);
    commit('SET_LOGS', null);
    if (process.env.VUE_APP_USE_LARGE_FILE_UPLOAD
       && process.env.VUE_APP_USE_LARGE_FILE_UPLOAD === 'true') {
      try {
        const getPresignedUrl = `${process.env.VUE_APP_API_BASE_URL}/wellgroups/url`;
        const token = await getUserAuthToken();
        let result = await request(getPresignedUrl, 'GET', token);
        const { signedUrl, key } = JSON.parse(result);

        try {
          await uploadToS3(signedUrl, file);
        } catch (err) {
          console.error(`Unable to upload file: ${err}`);
          throw new Error('Unable to upload file');
        }

        const createWellgroupUrl = `${process.env.VUE_APP_API_BASE_URL}/wellgroups`;
        // convert file to multipart/form-data format
        const formData = new FormData();
        // formData.append('file', file);
        formData.append('displayName', displayName);
        formData.append('clientId', clientId);
        formData.append('objectName', key);
        formData.append('origFilename', file.name);

        if (folderId) {
          formData.append('folderId', folderId);
        }

        result = await request(createWellgroupUrl, 'POST', token, formData);

        const resultJson = JSON.parse(result);

        if (resultJson.status === 'error') {
          commit('SET_ERROR', 'Unable to create wellgroup');
          commit('SET_LOGS', resultJson.logs);
        } else {
          const { wellgroup, logs } = resultJson;

          const numWellsWithCoords = wellgroup.wells.reduce((acc, well) => {
            if (well.topSurfaceLatitude && well.topSurfaceLongitude) {
              return acc + 1;
            }
            return acc;
          }, 0);

          wellgroup.numWellsWithCoords = numWellsWithCoords;

          commit('SET_LOGS', logs);
          commit('CREATE_WELLGROUP', wellgroup);
        }
      } catch (err) {
        commit('SET_ERROR', err);
      }
    } else {
      try {
        const url = `${process.env.VUE_APP_API_BASE_URL}/wellgroups`;
        const token = await getUserAuthToken();
        // convert file to multipart/form-data format
        const formData = new FormData();
        formData.append('file', file);
        formData.append('displayName', displayName);
        formData.append('clientId', clientId);

        if (folderId) {
          formData.append('folderId', folderId);
        }

        const result = await request(url, 'POST', token, formData);
        const json = JSON.parse(result);

        const { wellgroup, logs } = json;

        commit('SET_LOGS', logs);
        commit('CREATE_WELLGROUP', wellgroup);
      } catch (err) {
        commit('SET_ERROR', err);
      }
    }

    commit('SET_LOADING', { operation: 'create', loading: false });
  },
  async updateWellgroup({ commit }, { wellgroupId, displayName, file }) {
    commit('SET_LOADING', { operation: 'update', loading: true });
    commit('SET_ERROR', null);
    commit('SET_LOGS', null);

    const updateWellgroupUrl = `${process.env.VUE_APP_API_BASE_URL}/wellgroups/${wellgroupId}`;

    try {
      if (process.env.VUE_APP_USE_LARGE_FILE_UPLOAD
         && process.env.VUE_APP_USE_LARGE_FILE_UPLOAD === 'true') {
        const getPresignedUrl = `${process.env.VUE_APP_API_BASE_URL}/wellgroups/url`
          + `?wellgroupId=${wellgroupId}`;
        const token = await getUserAuthToken();
        let result = await request(getPresignedUrl, 'GET', token);
        const { signedUrl, key } = JSON.parse(result);

        await uploadToS3(signedUrl, file);

        // convert file to multipart/form-data format
        const formData = new FormData();
        formData.append('displayName', displayName);
        formData.append('objectName', key);
        formData.append('origFilename', file.name);

        result = await request(updateWellgroupUrl, 'PATCH', token, formData);

        const { wellgroup, logs } = JSON.parse(result);

        const numWellsWithCoords = wellgroup.wells.reduce((acc, well) => {
          if (well.topSurfaceLatitude && well.topSurfaceLongitude) {
            return acc + 1;
          }
          return acc;
        }, 0);

        commit('SET_LOGS', logs);
        commit('UPDATE_WELLGROUP', {
          wellgroupId,
          displayName,
          filename: file.name,
          numWellsWithCoords,
          updatedAt: wellgroup.updatedAt,
          wells: wellgroup.wells,
        });
      } else {
        const token = await getUserAuthToken();
        // convert file to multipart/form-data format
        const formData = new FormData();
        formData.append('file', file);
        formData.append('displayName', displayName);
        const result = await request(updateWellgroupUrl, 'PATCH', token, formData);
        const { updatedAt, logs } = JSON.parse(result);
        commit('SET_LOGS', logs);
        commit('UPDATE_WELLGROUP', {
          wellgroupId,
          displayName,
          filename: file.name,
          updatedAt,
        });
      }
    } catch (err) {
      commit('SET_ERROR', err);
    }
    commit('SET_LOADING', { operation: 'update', loading: false });
  },
  async fetchWellgroupFolders({ commit }, { clientId }) {
    commit('SET_LOADING', { operation: 'read', loading: true });
    commit('SET_ERROR', null);
    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/clients/${clientId}/wellgroupfolders`;
      const token = await getUserAuthToken();
      const result = await request(url, 'GET', token);
      const wellgroups = JSON.parse(result);
      commit('SET_WELLGROUP_FOLDERS', wellgroups);
    } catch (err) {
      commit('SET_ERROR', err);
      console.error(`Unable to retrieve well groups folders: ${err}`);
    }
    commit('SET_LOADING', { operation: 'read', loading: false });
  },
  async createWellgroupFolder({ commit }, { clientId, folderName }) {
    commit('SET_LOADING', { operation: 'createFolder', loading: true });
    commit('SET_ERROR', null);

    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/clients/${clientId}/wellgroupfolders`;
      const token = await getUserAuthToken();

      const formData = new FormData();
      formData.append('folderName', folderName);

      const result = await request(url, 'POST', token, formData);
      const folder = JSON.parse(result);

      commit('CREATE_WELLGROUP_FOLDER', folder);
    } catch (err) {
      console.error(`Error creating folder: ${err}`);
      commit('SET_ERROR', err);
    }
    commit('SET_LOADING', { operation: 'createFolder', loading: false });
  },
  async deleteWellgroupFolder({ commit }, { clientId, folderId }) {
    commit('SET_LOADING', { operation: 'deleteFolder', loading: true });
    commit('SET_ERROR', null);

    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/clients/${clientId}/wellgroupfolders`;
      const token = await getUserAuthToken();

      const formData = new FormData();
      formData.append('folderId', folderId);

      const result = await request(url, 'DELETE', token, formData);
      const folder = JSON.parse(result);

      console.log(`Deleted folder: ${folder}`);

      commit('DELETE_WELLGROUP_FOLDER', folderId);
    } catch (err) {
      console.error(`Error creating folder: ${err}`);
      commit('SET_ERROR', err);
    }
    commit('SET_LOADING', { operation: 'deleteFolder', loading: false });
  },
  async updateWellgroupFolder({ commit }, { wellgroupId, folderId }) {
    commit('SET_LOADING', { operation: 'updateFolder', loading: true });
    commit('SET_ERROR', null);

    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/wellgroups/${wellgroupId}`;
      const token = await getUserAuthToken();

      const formData = new FormData();
      formData.append('folderId', folderId);

      await request(url, 'PATCH', token, formData);

      commit('UPDATE_WELLGROUP_FOLDER', { wellgroupId, folderId });
    } catch (err) {
      console.error(`Error creating folder: ${err}`);
      commit('SET_ERROR', err);
    }
    commit('SET_LOADING', { operation: 'updateFolder', loading: false });
  },
};

const mutations = {
  SET_WELLGROUPS(state, wellgroups) {
    state.wellgroups = wellgroups;
  },
  CLEAR_WELLGROUPS(state) {
    state.wellgroups = [];
  },
  CREATE_WELLGROUP(state, wellgroup) {
    if (!state.wellgroups) {
      state.wellgroups = [];
    }

    state.wellgroups.push(wellgroup);
  },
  DELETE_WELLGROUP(state, id) {
    const index = state.wellgroups.findIndex((wellgroup) => wellgroup.id === id);
    state.wellgroups.splice(index, 1);
  },
  UPDATE_WELLGROUP(state, {
    wellgroupId,
    filename,
    displayName,
    updatedAt,
    numWellsWithCoords,
    wells,
  }) {
    const wellgroup = state.wellgroups.find((w) => w.id === wellgroupId);
    if (wellgroup) {
      wellgroup.displayName = displayName;
      wellgroup.origFilename = filename;
      wellgroup.updatedAt = updatedAt;
      wellgroup.numWellsWithCoords = numWellsWithCoords;
      wellgroup.wells = wells;
    }
  },
  SET_LOADING(state, { operation, loading }) {
    state.status.loading[operation] = loading;
  },
  SET_ERROR(state, error) {
    state.status.error = error;
  },
  SET_LOGS(state, logs) {
    state.status.logs = logs;
  },
  SET_WELLGROUP_FOLDERS(state, folders) {
    state.folders = folders;
  },
  CREATE_WELLGROUP_FOLDER(state, folder) {
    state.folders.push(folder);
  },
  DELETE_WELLGROUP_FOLDER(state, folderId) {
    const index = state.folders.findIndex((folder) => folder.id === folderId);
    state.folders.splice(index, 1);
  },
  UPDATE_WELLGROUP_FOLDER(state, { wellgroupId, folderId }) {
    const wellgroup = state.wellgroups.find((w) => w.id === wellgroupId);
    wellgroup.folderId = folderId;
  },
};

const state = () => ({
  wellgroups: null,
  folders: [],
  status: {
    loading: {
      create: false,
      read: false,
      update: false,
      delete: false,
      createFolder: false,
      deleteFolder: false,
      updateFolder: false,
    },
    error: null,
    logs: null,
  },
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
