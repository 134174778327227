/* eslint-disable */

// TODO: There is a current issue where using Amplify Authenticator components don't allow
// request of custom scopes for access token, so we can't use the access token to verify
// our API Gateway endpoints access. In the meantime, we use the identity token (which
// make Cognito resource servers useless at this point.) Once this issue is resolved, we
// can switch to using access token and custom scopes for API Gateway authorization.
// Issue can be tracked here:
// https://github.com/aws-amplify/amplify-js/issues/1370

const awsconfig = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": process.env.VUE_APP_AWS_USERPOOL_ID,
    "aws_user_pools_web_client_id": process.env.VUE_APP_AWS_APPCLIENT_ID,
    "oauth": {
        "domain": process.env.VUE_APP_AWS_OAUTH_DOMAIN,
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": `${window.location.origin}/signin`,
        "redirectSignOut": `${window.location.origin}/signout`,
        "responseType": "code"
    },
    "aws_cognito_username_attributes": [
        "USERNAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    }
};

export default awsconfig;
