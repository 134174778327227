import { Auth } from 'aws-amplify';
import { createRouter, createWebHistory } from 'vue-router';
import { LoadingBar } from '@varlet/ui';
import store from '../store';

LoadingBar.setDefaultOptions({
  color: '#ff6900',
  height: '5px',
  top: '54px',
});

async function requireClients(to, from, next) {
  if (!store.state.clients.clients) {
    LoadingBar.resetDefaultOptions();
    LoadingBar.setDefaultOptions({
      color: '#ff6900',
      height: '5px',
    });
    LoadingBar.start();
    await store.dispatch('clients/fetchClients', null, { root: true });
  } else {
    LoadingBar.setDefaultOptions({
      color: '#ff6900',
      height: '5px',
      top: '54px',
    });
    LoadingBar.start();
  }

  // check if client exists
  if (to.params.clientId) {
    // console.log('client check');
    const client = store.getters['clients/getClient'](parseInt(to.params.clientId, 10));
    if (!client) {
      LoadingBar.finish();

      next('/:pathMatch(.*)*');
      return;
    }
  }

  LoadingBar.finish();
  next();
}

async function requireWellgroups(to, from, next) {
  LoadingBar.start();

  // Only update wellgroups when client changes
  if (to.params.clientId !== from.params.clientId) {
    await store.dispatch('wellgroups/fetchWellgroups', { clientId: to.params.clientId }, { root: true });
    await store.dispatch('wellgroups/fetchWellgroupFolders', { clientId: to.params.clientId }, { root: true });
  }
  // check if wellgroup exists
  if (to.params.wellgroupId) {
    const wellgroup = store.getters['wellgroups/getWellgroup'](to.params.wellgroupId);
    if (!wellgroup) {
      // TODO: I think this is wrong. Need to investigate
      next('/:pathMatch(.*)*');

      LoadingBar.finish();

      return;
    }
  }

  LoadingBar.finish();

  next();
}

async function requireUsers(to, from, next) {
  LoadingBar.start();
  // Only update wellgroups when client changes
  if (to.params.clientId !== from.params.clientId) {
    // console.log('Users fetched');
    await store.dispatch('users/fetchUsers', { clientId: to.params.clientId }, { root: true });
  }

  LoadingBar.finish();

  next();
}

const routes = [
  {
    name: 'HomePage',
    path: '/',
    // User get redirected here to sign in. Once signed in, the are redirected to /clients
    // This is done to avoid fetching (before navigation) with user not signed in.
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: '/singin',
    redirect: '/',
  },
  {
    path: '/singout',
    redirect: '/',
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/PageNotFound.vue'),
  },
  {
    name: 'ClientList',
    path: '/clients',
    component: () => import('@/views/ClientList.vue'),
    beforeEnter: requireClients,
  },
  {
    name: 'ClientCreate',
    path: '/clients/create',
    component: () => import('@/views/ClientCreate.vue'),
    beforeEnter: requireClients,
  },
  {
    name: 'ClientPage',
    path: '/clients/:clientId(\\d+)',
    redirect: '/clients',
    component: () => import('@/views/ClientPage.vue'),
    beforeEnter: [requireClients],
    params: true,
    children: [
      {
        name: 'ClientEdit',
        path: 'edit',
        component: () => import('@/views/ClientEdit.vue'),
        beforeEnter: requireClients,
      },
      {
        name: 'WellgroupList',
        path: 'wellgroups',
        component: () => import('@/views/WellgroupList.vue'),
        beforeEnter: requireWellgroups,
      },
      {
        name: 'WellgroupCreate',
        path: 'wellgroups/create',
        component: () => import('@/views/WellgroupCreate.vue'),
        beforeEnter: requireWellgroups,
      },
      {
        name: 'WellgroupEdit',
        path: 'wellgroups/:wellgroupId([0-9a-f\\-]+)/edit',
        component: () => import('@/views/WellgroupEdit.vue'),
        beforeEnter: requireWellgroups,
      },
      {
        name: 'UserList',
        path: 'users',
        component: () => import('@/views/UserList.vue'),
        beforeEnter: requireUsers,
      },
      {
        name: 'UserCreate',
        path: 'users/create',
        component: () => import('@/views/UserCreate.vue'),
        beforeEnter: requireUsers,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

/* eslint-disable consistent-return */
router.beforeEach(async (to, from, next) => {
  let isAuthenticated;
  try {
    await Auth.currentAuthenticatedUser();
    isAuthenticated = true;
  } catch (err) {
    isAuthenticated = false;
  }

  if ((to.name !== 'HomePage' && !isAuthenticated)) {
    return next({ name: 'HomePage' });
  }

  /*
   * If starting app from some path other home page (i.e. /clients)
   * redirect back to clients, so that back button navigation works
   * correctly
   */
  if (isAuthenticated && from.path === '/' && to.path !== '/clients') {
    return next({ name: 'ClientList' });
  }

  return next();
});

export default router;
