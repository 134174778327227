<template>
  <authenticator :hide-sign-up="true">
    <template v-slot:header>
      <div class="authenticator-title">
        <h1>Blackstone RavenVR Admin Login</h1>
      </div>
    </template>
    <var-app-bar title="RavenVR Admin Dashboard">
      <template #left v-if="showBackButton">
        <var-button color="transparent" round text @click="$router.back()">
          <var-icon name="chevron-left" :size="24"/>
        </var-button>
      </template>
      <template #right>
        <var-button class="appbar-menu__text-button" text color="transparent" @click="reportIssue">
          Report Issue
        </var-button>
        <var-menu v-model:show="showMenu" :offset-y="40" :offset-x="-45">
          <var-button round text color="transparent" @click="showMenu = true">
            <var-icon name="menu" :size="24"/>
          </var-button>
          <template #menu>
            <div>
              <var-cell class="appbar-menu__cell" v-ripple @click="signOut">
                Sign Out
              </var-cell>
              <var-cell class="appbar-menu__cell" v-ripple @click="about">
                About
              </var-cell>
            </div>
          </template>
        </var-menu>
      </template>
    </var-app-bar>
    <var-dialog v-model:show="showAbout" title="About"
        :cancelButton=false
        confirmButtonText="OK">
      Version: {{ $store.state.about.version }}
    </var-dialog>
    <div class="app-content">
      <router-view></router-view>
    </div>
  </authenticator>
</template>

<script setup>
import { Authenticator } from '@aws-amplify/ui-vue';
import { Auth } from 'aws-amplify';
import '@aws-amplify/ui-vue/styles.css';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const showMenu = ref(false);
const showBackButton = computed(() => route.name !== 'ClientList');
const showAbout = ref(false);

function signOut() {
  showMenu.value = false;
  Auth.signOut();
}

function about() {
  showAbout.value = true;
}

function reportIssue() {
  showMenu.value = false;
  window.open(process.env.VUE_APP_REPORT_ISSUE_URL, '_blank');
}

</script>

<style lang="scss">
  @import './assets/css/_colors.module';

  /* Override Amplify min-height */
  body {
    min-height: 50vh;
  }

  .authenticator-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-primary;
  }

  .appbar-menu__cell {
    background-color: white;
    cursor: pointer;
  }

  .amplify-button[data-variation='primary'] {
    background-color: $color-primary;
  }

  .amplify-button[data-variation='primary']:hover {
    background-color: $color-primary-rgba-dark;
  }

  h1 {
    font-size: 1.8em !important;
  }

  [data-amplify-authenticator] {
    --amplify-components-button-link-color: #ff6900;
    --amplify-components-button-link-color-hover: #232f3e;
    --amplify-components-button-link-hover-background-color: white;
  }

  /* Override Varlet app bar to make fixed */
  .var-app-bar {
    position: fixed !important;
    top: 0;
    z-index: 1000 !important;
  }

  /* Accommodate fixed app bar */
  .app-content {
    margin-top: 54px;
  }
</style>
