import { createStore } from 'vuex';
import wellgroups from './modules/wellgroups';
import clients from './modules/clients';
import users from './modules/users';
import about from './modules/about';

const store = createStore({
  modules: {
    wellgroups,
    clients,
    users,
    about,
  },
  strict: true,
});

export default store;
