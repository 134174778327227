import { request, getUserAuthToken } from '../utils';

const getters = {
  getUsers: (state) => state.users,
  getUser: (state) => (userId) => state.users.find((u) => u.id === userId),
};

const actions = {
  async fetchUsers({ commit }, { clientId }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/clients/${clientId}/users`;
      const token = await getUserAuthToken();
      const result = await request(url, 'GET', token);
      const users = JSON.parse(result);
      commit('SET_USERS', users);
    } catch (err) {
      console.error(`Unable to retrieve list of users: ${err}`);
      commit('SET_ERROR', err);
    }
    commit('SET_LOADING', false);
  },
  async createUser({ commit }, { clientId, username, email }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/clients/${clientId}/users`;
      const token = await getUserAuthToken();
      const formData = new FormData();
      formData.append('username', username);
      formData.append('email', email);
      const result = await request(url, 'POST', token, formData);
      const user = JSON.parse(result);
      commit('CREATE_USER', user);
    } catch (err) {
      console.error(`Unable to create user: ${err}`);
      commit('SET_ERROR', err);
    }
    commit('SET_LOADING', false);
  },
  async deleteUser({ commit }, { userId }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/users/${userId}`;
      const token = await getUserAuthToken();
      await request(url, 'DELETE', token);
      commit('DELETE_USER', userId);
    } catch (err) {
      commit('SET_ERROR', err);
    }
    commit('SET_LOADING', false);
  },
};

const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
  CLEAR_USERS(state) {
    state.users = [];
  },
  SET_LOADING(state, loading) {
    state.status.loading = loading;
  },
  SET_ERROR(state, error) {
    state.status.error = error;
  },
  CREATE_USER(state, user) {
    state.users.push(user);
  },
  DELETE_USER(state, id) {
    const index = state.users.findIndex((user) => user.id === id);
    state.users.splice(index, 1);
  },
};

const state = () => ({
  users: null,
  status: {
    loading: false,
    error: null,
  },
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
