import { request, getUserAuthToken } from '../utils';

const getters = {
  getClients: (state) => {
    // Sort clients by name
    if (state.clients) {
      const sortedClients = [...state.clients].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      return sortedClients;
    }

    return [];
  },
  getClient: (state) => (clientId) => state.clients.find((c) => c.id === clientId),
};

const actions = {
  async fetchClients({ commit }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/clients`;
      const token = await getUserAuthToken();
      const result = await request(url, 'GET', token);
      const clients = JSON.parse(result);
      commit('SET_CLIENTS', clients);
    } catch (err) {
      console.error(`Unable to retrieve list of clients: ${err}`);
      commit('SET_ERROR', err);
    }
    commit('SET_LOADING', false);
  },
  async createClient({ commit }, { name }) {
    commit('SET_CREATING', true);
    commit('SET_ERROR', null);
    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/clients`;
      const token = await getUserAuthToken();
      const formData = new FormData();
      formData.append('name', name);
      const result = await request(url, 'POST', token, formData);
      const client = JSON.parse(result);
      commit('CREATE_CLIENT', client);
    } catch (err) {
      console.error('Failed to create client');
      commit('SET_ERROR', err);
    }
    commit('SET_CREATING', false);
  },
  async updateClient({ commit }, { clientId, name }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/clients/${clientId}`;
      const token = await getUserAuthToken();
      const formData = new FormData();
      formData.append('name', name);
      await request(url, 'PATCH', token, formData);
      commit('UPDATE_CLIENT', {
        clientId,
        name,
      });
    } catch (err) {
      commit('SET_ERROR', err);
    }
    commit('SET_LOADING', false);
  },
  async deleteClient({ commit }, { clientId }) {
    commit('SET_DELETING', true);
    commit('SET_ERROR', null);

    try {
      const url = `${process.env.VUE_APP_API_BASE_URL}/clients/${clientId}`;
      const token = await getUserAuthToken();
      await request(url, 'DELETE', token);
      commit('DELETE_CLIENT', {
        clientId,
      });
    } catch (err) {
      console.error('Failed to delete client');
      commit('SET_ERROR', err);
    }
    commit('SET_DELETING', false);
  },
};

const mutations = {
  SET_CLIENTS(state, clients) {
    state.clients = clients;
  },
  CLEAR_CLIENTS(state) {
    state.clients = [];
  },
  SET_LOADING(state, loading) {
    state.status.loading = loading;
  },
  SET_DELETING(state, deleting) {
    state.status.deleting = deleting;
  },
  SET_CREATING(state, creating) {
    state.status.creating = creating;
  },
  SET_ERROR(state, error) {
    state.status.error = error;
  },
  CREATE_CLIENT(state, client) {
    if (!state.clients) {
      state.clients = [];
    }

    state.clients.push(client);
  },
  UPDATE_CLIENT(state, { clientId, name }) {
    const client = state.clients.find((c) => c.id === clientId);
    if (client) {
      client.name = name;
    }
  },
  DELETE_CLIENT(state, { clientId }) {
    const clientIndex = state.clients.findIndex((c) => c.id === clientId);

    state.clients.splice(clientIndex, 1);
  },
};

const state = () => ({
  clients: null,
  status: {
    loading: false,
    deleting: false,
    creating: false,
    error: null,
  },
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
